function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function show_dia(text, i, time) {
  const $dia_i = $(".orders .dia_message i");
  $dia_i.show();
  if (i) {
    $dia_i.attr("class", "fa");
    $dia_i.addClass(i);
  } else {
    $dia_i.hide();
  }

  $(".orders .dia_message .text_mess").text(text);

  $(".orders .dia_message").show();
  if (time !== undefined) {
    setTimeout(function () {
      $(".orders .dia_message").hide();
    }, time);
  }
}

function district_ident(adres) {
  ymaps.ready(init);

  function init() {
    ymaps
      .geocode(adres, { results: 1 })

      .then(function (res) {
        return ymaps.geocode(res.geoObjects.get(0).geometry.getCoordinates(), {
          kind: "district",
          results: 1,
        });
      })

      .then(function (res) {
        // console.log(res.geoObjects.get(0).properties.getAll());
      });
  }
}

//$('[name=login]').mask('7(999)999-99-99');

(function () {
  $.ajax({
    type: "post",
    url: "../orders/save_type_client",
    data: { type_client: "web" },
    dataType: "json",
    success: function (json) {
      if (json.err) {
        console.log(json.err);
      }
    },
  });
})();

function set_coordinates() {
  if (ymaps !== undefined) {
    ymaps.ready(init);

    function init() {
      ymaps.geolocation.get().then(
        function (res) {
          const bound = res.geoObjects.get(0).properties.getAll();
          // console.log(bound.boundedBy[1]);

          const data = {
            latitude: bound.boundedBy[1][0],
            longitude: bound.boundedBy[1][1],
          };

          $.ajax({
            type: "post",
            url: "../orders/set_coord",
            data: data,
            dataType: "json",
            success: function (json) {
              // console.log(json);
            },
          });
        },
        function (e) {
          console.log(e);
        }
      );
    }
  }
}

// set_coordinates();

function list_prav() {
  if ($("div").is(".main_list_order")) {
    // $(window).height();

    const main_list_size = $(".main_list_order .order").size();

    $(".head_read").css({
      "margin-top": 50 + 50 * main_list_size + "px",
    });

    $(".list_orders").css({
      "margin-top": 80 + 50 * main_list_size + "px",
    });
  }
}

function gererate_quest() {
  const num_two = _.random(0, 9),
    num_one = _.random(0, 9);

  const quest = num_one + " + " + num_two + " =";
  localStorage.setItem("answer", num_one + num_two);
  return quest;
}

function show_order() {
  $(".main_list_order , .head_read , .list_orders").show();
}

function hide_order() {
  $(".main_list_order , .head_read , .list_orders").hide();
}

function filt_contact_dann(dann) {
  const data = {};
  data.Contacts = [];

  // console.log(dann);
  if (_.has(dann, "Contacts")) {
    if (isJson(dann.Contacts)) {
      dann.Contacts = JSON.parse(dann.Contacts);
    }

    if (!_.isEmpty(dann.Contacts)) {
      data.Contacts = dann.Contacts;
    }
  }
  if (_.has(dann, "Organization") && !_.isEmpty(dann.Organization)) {
    data.Organization = dann.organization;
  }

  if (!_.isEmpty(dann.email)) {
    data.Email = dann.email;
  }
  if (!_.isEmpty(dann.inn)) {
    data.INN = dann.inn;
  }
  if (!_.isEmpty(dann.cpp)) {
    data.CPP = dann.cpp;
  }
  if (!_.isEmpty(dann.bankaccount)) {
    data.BankAccount = dann.bankaccount;
  }
  if (!_.isEmpty(dann.operatingaccount)) {
    data.OperatingAccount = dann.operatingaccount;
  }
  if (!_.isEmpty(dann.bank)) {
    data.Bank = dann.bank;
  }
  if (!_.isEmpty(dann.bic)) {
    data.BIC = dann.bic;
  }
  if (!_.isEmpty(dann.rCity)) {
    data.rCity = dann.rCity;
  }
  if (!_.isEmpty(dann.rStreet)) {
    data.rStreet = dann.rStreet;
  }
  if (!_.isEmpty(dann.rHouse)) {
    data.rHouse = dann.rHouse;
  }
  if (!_.isEmpty(dann.rCorpus)) {
    data.rCorpus = dann.rCorpus;
  }
  if (!_.isEmpty(dann.rEntrance)) {
    data.rEntrance = dann.rEntrance;
  }
  if (!_.isEmpty(dann.rEntrance)) {
    data.rEntrance = dann.rEntrance;
  }
  if (!_.isEmpty(dann.rFloor)) {
    data.rFloor = dann.rFloor;
  }
  if (!_.isEmpty(dann.rRoom)) {
    data.rRoom = dann.rRoom;
  }
  if (!_.isEmpty(dann.fCity)) {
    data.fCity = dann.fCity;
  }
  if (!_.isEmpty(dann.fStreet)) {
    data.fStreet = dann.fStreet;
  }
  if (!_.isEmpty(dann.fHouse)) {
    data.fHouse = dann.fHouse;
  }
  if (!_.isEmpty(dann.fCorpus)) {
    data.fCorpus = dann.fCorpus;
  }
  if (!_.isEmpty(dann.fEntrance)) {
    data.fEntrance = dann.fEntrance;
  }
  if (!_.isEmpty(dann.fFloor)) {
    data.fFloor = dann.fFloor;
  }
  if (!_.isEmpty(dann.fRoom)) {
    data.fRoom = dann.fRoom;
  }

  return data;
}

function subs_local_val(dann, data) {
  const values = {};

  for (const ks in data) {
    if (
      ks != "Contacts" &&
      ks != "rCity" &&
      ks != "rStreet" &&
      ks != "rHouse" &&
      ks != "rCorpus" &&
      ks != "rEntrance" &&
      ks != "rFloor" &&
      ks != "rRoom" &&
      ks != "fCity" &&
      ks != "fStreet" &&
      ks != "fHouse" &&
      ks != "fCorpus" &&
      ks != "fEntrance" &&
      ks != "fFloor" &&
      ks != "fRoom"
    ) {
      data[_.toLower(ks)] = data[ks];
    }
  }

  for (const key in dann) {
    if (key == "Contacts") {
      if (isJson(dann.Contacts)) {
        dann.Contacts = JSON.parse(dann.Contacts);
      }

      if (!_.isEmpty(dann.Contacts)) {
        values.Contacts = dann.Contacts;
      } else {
        values.Contacts = data.Contacts;
      }
    }
    if (!_.isEmpty(dann[key])) {
      values[key] = dann[key];
    } else {
      values[key] = data[key];
    }
  }
  return values;
}

function create_order(dann) {
  if (
    !_.isEmpty(dann) &&
    dann.status == 1 &&
    !$(".list_orders .order").is("[data-ord-id=" + dann.id + "]") &&
    dann.dept == $(".orders").attr("data-dept")
  ) {
    const templ = _.template(/* html */ `<div data-ord-id="<%= dann.id %>"  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 order">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ord_dan">
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 ord_type">
      <%= dann.type %>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-center ord_time">
      <%= dann.date +"  "+ dann.time %>
      </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left ord_device">
      <%= dann.device %>
      </div>
      <% if(!_.isEmpty(dann.Contacts)){%>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left ord_contact">
      <%= dann["Contacts"][0]["Person"] %>
      </div>
      <%} else{%>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left ord_contact">
      <%= dann["client"] %>
       </div>
      <%}%>
      <div class = "col-xs-10 col-sm-10 col-md-10 col-lg-10 ord_address" >
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 adres_top">
      <% if(!_.isEmpty(dann["city"])){ %>
      <%= dann["city"] %>
      <%}%>
      <span> <% if(!_.isEmpty(dann["metro"])){  %>
      <%= dann["metro"] %>
      <%}%> 
      </span>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 adres_bot">
      <%= dann["street"] %> <%= dann["house"] %>
      </div>
      </div>
      <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 ord_category">
      <%= dann["category"] %>
      </div>
      </div>`);

    $(".list_orders").prepend(templ({ dann: dann }));
  }

  if (dann.status !== undefined && dann.status > 1) {
    create_main_order(dann);
  }
}

function create_main_order(dann) {
  //console.log(dann);
  if (!dann) {
    $(".list_orders").empty();
  } else {
    if (
      dann.engineer_id == $(".orders").attr("data-user-id") &&
      dann.status != 8 &&
      dann.dept == $(".orders").attr("data-dept")
    ) {
      if (
        $(".list_orders .order").is("[data-ord-id=" + dann.id + "]") &&
        dann.status > 1
      ) {
        $(".list_orders .order[data-ord-id=" + dann.id + "]").remove();
      }

      if ($(".main_list_order .order").is("[data-ord-id=" + dann.id + "]")) {
        const $ord = $(".main_list_order .order[data-ord-id=" + dann.id + "]");
        $ord.attr("data-order-status", dann.status);

        if (dann.status == 2) {
          $ord.find(".status_text").text("Вас ожидает клиент");
        } else if (dann.status == 3) {
          $ord.find(".status_text").text("В пути к заказчику");
        } else if (dann.status == 4) {
          $ord.find(".status_text").text("Выполняю заказ");
        } else if (dann["status"] == 5) {
          $ord.find(".status_text").text("Выполнен");
        } else if (dann["status"] == 6) {
          $ord.find(".status_text").text("Продолжение");
        } else if (dann["status"] == 7) {
          $ord.find(".status_text").text("Выставлен счет");
        }
      } else {
        let ord =
          '<div data-ord-id="' +
          dann["id"] +
          '" data-order-status="' +
          dann["status"] +
          '"' +
          'class="col-xs-12 col-sm-12 col-md-12 col-lg-12 order">' +
          '<div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">' +
          dann["id"] +
          "</div>" +
          '<div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 text-center status_text">';

        if (dann["status"] == 2) {
          ord += "Вас ожидает клиент";
        } else if (dann["status"] == 3) {
          ord += "В пути к заказчику";
        } else if (dann["status"] == 4) {
          ord += "Выполняю заказ";
        } else if (dann["status"] == 5) {
          ord += "Выполнен";
        } else if (dann["status"] == 6) {
          ord += "Продолжение";
        } else if (dann["status"] == 7) {
          ord += "Выставлен счет";
        }
        ord +=
          "</div>" +
          '<div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 text-center ord_time">' +
          dann.date +
          " " +
          dann.time +
          "</div>" +
          "</div>";

        if ($("div").is(".main_list_order")) {
          $(".main_list_order").prepend(ord);
        } else {
          const main_list_order =
            '<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 main_list_order"></div>';

          $(".orders .menu").after(main_list_order);
          $(".main_list_order").html(ord);
        }
      }
    } else {
      $(".main_list_order .order[data-ord-id=" + dann.id + "]").remove();
    }

    if (dann.status == 1) {
      $(".main_list_order .order[data-ord-id=" + dann.id + "]").remove();
      create_order(dann);
    }

    if (dann.status == 8 || dann.dept != $(".orders").attr("data-dept")) {
      $(`.order[data-ord-id= ${dann.id} ]`).remove();
    }
  }
}

function fn_bank_account(Str) {
  let result = false;
  let Sum = 0;

  const v = [
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1,
    3,
    7,
    1,
  ];

  for (let i = 0; i <= 22; i++) {
    Sum = Sum + ((Number(Str.charAt(i)) * v[i]) % 10);
  }

  if (Sum % 10 == 0) {
    result = true;
  }

  return result;
}

function fn_checkKS(Account, BIK) {
  return fn_bank_account("0" + BIK.substr(4, 2) + Account);
}

function fn_checkRS(Account, BIK) {
  return fn_bank_account(BIK.substr(6, 3) + Account);
}

function filter_yur(data) {
  let res = true,
    mess = [];

  // console.log(data);

  if (_.has(data, "Contacts") && _.has(data.Contacts[0], "Person")) {
    const name = data.Contacts[0].Person;

    if (name.length <= 2) {
      mess.push("Имя должно быть длиннее двух символов");

      res = false;
    }
  } else {
    mess.push("Зполните поле имя");

    res = false;
  }

  if (_.has(data, "Contacts") && _.has(data.Contacts[0], "Phone")) {
    const phone = data.Contacts[0].Phone;

    if (!phone.match(/7[0-9]{10}/)) {
      mess.push("Неверный формат телефона");
      res = false;
    }
  } else {
    mess.push("Зполните поле телефон");

    res = false;
  }

  if (_.has(data, "Email")) {
    const email = data.Email;

    if (!email.match(/.*@.*/)) {
      mess.push("Неверный формат Email");

      res = false;
    }
  } else {
    mess.push("Зполните поле Email");

    res = false;
  }

  if (_.has(data, "INN")) {
    const inn = data.INN;

    if (
      (inn.length == 10 &&
        inn[9] !=
          ((2 * inn[0] +
            4 * inn[1] +
            10 * inn[2] +
            3 * inn[3] +
            5 * inn[4] +
            9 * inn[5] +
            4 * inn[6] +
            6 * inn[7] +
            8 * inn[8]) %
            11) %
            10) ||
      (inn.length == 12 &&
        inn[10] !=
          ((7 * inn[0] +
            2 * inn[1] +
            4 * inn[2] +
            10 * inn[3] +
            3 * inn[4] +
            5 * inn[5] +
            9 * inn[6] +
            4 * inn[7] +
            6 * inn[8] +
            8 * inn[9]) %
            11) %
            10 &&
        inn[11] ==
          ((3 * inn[0] +
            7 * inn[1] +
            2 * inn[2] +
            4 * inn[3] +
            10 * inn[4] +
            3 * inn[5] +
            5 * inn[6] +
            9 * inn[7] +
            4 * inn[8] +
            6 * inn[9] +
            8 * inn[10]) %
            11) %
            10)
    ) {
      mess.push("Неверный формат INN");

      res = false;
    }
  } else {
    mess.push("Зполните поле INN");
    res = false;
  }

  if (_.has(data, "CPP")) {
    const cpp = data.CPP;

    if (cpp.length != 9 || !cpp.match(/\d{4}[\dA-Z][\dA-Z]\d{3}/)) {
      mess.push("Неверный формат КПП");

      res = false;
    }
  } else {
    mess.push("Зполните поле КПП");

    res = false;
  }

  if (_.has(data, "BIC")) {
    const bic = data.BIC;

    if (bic.length != 9) {
      mess.push("Неверный формат БИК");
      res = false;
    }
  } else {
    mess.push("Зполните поле БИК");

    res = false;
  }

  if (_.has(data, "BankAccount")) {
    let bankaccount = data.BankAccount,
      bic;

    if (_.has(data, "BIC")) {
      bic = data.BIC;

      if (!fn_checkRS(bankaccount, bic)) {
        mess.push("Неверный формат номера счета");

        res = false;
      }
    } else {
      mess.push("Зполните поле БИК");
      res = false;
    }
  } else {
    mess.push("Зполните поле номера счета");

    res = false;
  }

  if (_.has(data, "OperatingAccount")) {
    let korrsc = data.OperatingAccount,
      bic;

    if (_.has(data, "BIC")) {
      bic = data.BIC;

      if (!fn_checkKS(korrsc, bic)) {
        mess.push("Неверный формат номера корр. счета");

        res = false;
      }
    } else {
      mess.push("Зполните поле БИК");
      res = false;
    }
  } else {
    mess.push("Зполните поле номера корр. счета");

    res = false;
  }

  if (_.has(data, "Organization")) {
    const organization = data.Organization;

    if (organization.length <= 2) {
      mess.push("Неверный формат названия организации");

      res = false;
    }
  } else {
    mess.push("Зполните поле организация");

    res = false;
  }

  if (_.has(data, "rCity")) {
    const city = data.rCity;

    if (city.length < 2) {
      mess.push("Неверный формат поля Город");

      res = false;
    }
  } else {
    mess.push("Заполните поле Город в юридических реквизитах");

    res = false;
  }

  if (_.has(data, "rHouse")) {
    const hus = data.rHouse;

    if (_.isEmpty(hus) || _.isNaN(+hus)) {
      mess.push("Неверный формат поля Дом");

      res = false;
    }
  } else {
    mess.push("Заполните поле Дом в юридических реквизитах");

    res = false;
  }

  if (_.has(data, "rRoom")) {
    const room = data.rRoom;

    if (_.isEmpty(room)) {
      mess.push("Неверный формат поля Офис");

      res = false;
    }
  } else {
    mess.push("Заполните поле Офис в юридических реквизитах");

    res = false;
  }
  if (_.has(data, "rStreet")) {
    const street = data.rStreet;

    if (street.length < 3) {
      mess.push("Неверный формат поля улица");

      res = false;
    }
  } else {
    mess.push("Заполните поле улица в юридических реквизитах");

    res = false;
  }

  if (!_.isEmpty(mess)) {
    let ms = "";
    mess.forEach((val, ind) => {
      ms += `${val} \n`;
    });

    show_dia(ms, "", 3000);
  }

  return res;
}

function toFixed(num, fixed) {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

$(document).on("click", ".call_client", function () {
  const order_id = $(this).parents(".open_order").attr("data-order-id");

  if (order_id) {
    $.ajax({
      type: "post",
      url: "https://m.ibot.pro/orders/get_call",
      dataType: "json",
      data: {
        order_id: order_id,
      },
      beforeSend: function () {
        show_dia("Отправка запроса", "fa-spinner fa-pulse fa-fw");
      },
      success: function (json) {
        if (
          json.dann == "Inner Busy" ||
          json.dann == "Inner Not Available" ||
          json.dann == "Inner Error" ||
          json.dann == "Inner Line Not Found"
        ) {
          show_dia("Нет ответа", "fa-bug", 3000);
        } else {
          show_dia("Успешно", "fa-check-circle", 3000);

          $(".open_order").attr("data-call", "block");
          const $call = $(".call_client");
          $call.attr("disabled", true);

          $call.html(`<span class="fa-stack fa-lg">
  <i style="font-size: 16px" class="fa fa-phone fa-stack-1x"></i>
  <i class="fa fa-ban fa-stack-2x text-danger"></i>
</span> <span>Позвонить</span>`);

          setTimeout(function () {
            $(".call_client").attr("disabled", false);
            $(".open_order").attr("data-call", "");
            $(".call_client").html(
              `<i class="fa fa-phone"></i><span>Позвонить</span>`
            );
          }, 50000);
        }
      },
    });
  }
});
